import './SignatureForm.scss';
import { useEffect, useState } from 'react';
import FieldText from '../components/FieldText';
import FieldPhone from '../components/FieldPhone';
import FieldSelect from '../components/FieldSelect';
import { getTeamEnumDescriptionBr, TeamsEnum } from '../lib/enums/TeamsEnum';
import FieldCheckbox from '../components/FieldCheckbox';
import { AwsBadgesEnum, getAwsBadgeEnumDescriptionBr } from '../lib/enums/AwsBadgesEnum';

function SignatureForm(props) {
    const { onChange, infoModal: setInfoModal } = props;

    const [ formData, setFormData ] = useState({
        name: '',
        familyName: '',
        team: '-1',
        phone: '',
        email: '',
        badges: [],
    });

    useEffect(() => {
        if (onChange) onChange(formData);
    }, [ formData ]);

    return (
        <div className={'signature-form'}>
            <form>
                <FieldText
                    // I18N
                    label={'Nome'}
                    // I18N
                    placeholder={'Primeiro nome'}
                    value={formData.name}
                    onChange={({ target }) => setFormData({ ...formData, name: target.value.replaceAll(' ', '') })}
                />
                <FieldText
                    // I18N
                    label={'Nome de família'}
                    // I18N
                    placeholder={'Apenas um nome de família'}
                    value={formData.familyName}
                    onChange={({ target }) => setFormData({ ...formData, familyName: target.value.replaceAll(' ', '') })}
                />
                <FieldSelect
                    // I18N
                    label={'Time'}
                    placeholder={'Selecione o time'}
                    value={formData.team}
                    options={(() => {
                        let array = [];
                        for (const key in TeamsEnum) {
                            const team = TeamsEnum[key];
                            array.push({ key: team, value: getTeamEnumDescriptionBr(team) });
                        }
                        return array;
                    })()}
                    onChange={({ target }) => setFormData({ ...formData, team: target.value })}
                />
                <FieldPhone
                    // I18N
                    label={'Telefone'}
                    // I18N
                    placeholder={'Telefone para contato direto (celular, por exemplo)'}
                    value={formData.phone}
                    onChange={({ target }) => setFormData({ ...formData, phone: target.value })}
                    onKeyUp={({ target }) => setFormData({ ...formData, phone: target.value })}
                />
                <FieldCheckbox
                    // I18N
                    label={'Certificados AWS'}
                    className={'badges'}
                    inputs={(() => {
                        let array = [];
                        for (const key in AwsBadgesEnum) {
                            const badge = AwsBadgesEnum[key];
                            array.push({
                                label: getAwsBadgeEnumDescriptionBr(badge),
                                checked: formData.badges.includes(badge),
                                onChange: ({ target }) => {
                                    setFormData((formData) => {
                                        let badges = formData.badges;
                                        if (target.checked) {
                                            if (badges.length < 3) {
                                                badges.push(badge);
                                            } else {
                                                setInfoModal({
                                                    // I18N
                                                    message: 'Você pode selecionar no máximo 3 certificados AWS.',
                                                    show: true,
                                                });
                                            }
                                        } else {
                                            badges = badges.filter((b) => b !== badge);
                                        }
                                        return { ...formData, badges };
                                    });
                                },
                            });
                        }
                        return array;
                    })()}
                />
            </form>
        </div>
    );
}

export default SignatureForm;
import './Preview.scss';
import { unmaskPhone } from '../components/PhoneInput';
import { getTeamEnumDescriptionBr } from '../lib/enums/TeamsEnum';
import { getAwsBadgeEnumDescriptionBr, getAwsBadgeUrl } from '../lib/enums/AwsBadgesEnum';
import { useRef, useState } from 'react';
import FieldCheckbox from '../components/FieldCheckbox';

function Preview(props) {
    let {
        name,
        familyName,
        team,
        phone,
        badges,
        infoModal: setInfoModal,
    } = props;

    const [ embedImages, setEmbedImages ] = useState(false);

    if (name && name.length > 2) {
        name = name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
    }
    if (familyName && familyName.length > 2) {
        familyName = familyName.charAt(0).toUpperCase() + familyName.substring(1).toLowerCase();
    }

    const urlBase = window.location.href;
    const tripleSlashUrl = `${urlBase}assets/images/triple-slash@2x.png?${new Date().getDate()}`;
    const certifiedBCorporationUrl = `${urlBase}assets/images/certified-b-corporation@2x.png?${new Date().getDate()}`;
    const awsPartnerAdvanced = `${urlBase}assets/images/aws-partner-advanced@2x.png?${new Date().getDate()}`;
    const pipeUrl = `${urlBase}assets/images/pipe@2x.png?${new Date().getDate()}`;
    const longPipeUrl = `${urlBase}assets/images/long-pipe@2x.png?${new Date().getDate()}`;
    const mainPhone = '(11) 3014 2103';

    const signatureRef = useRef();

    const tableAttributes = {
        border: 0,
        cellPadding: 0,
        cellSpacing: 0,
    };

    const imgStyle = {
        verticalAlign: 'unset',
        display: 'block'
    };

    const anchorStyle = {
        display: 'block',
        textDecoration: 'none',
    };

    const anchorFontStyle = {
        display: 'block',
        textDecoration: 'none',
    };

    const nameStyle = {
        fontFamily: 'sans-serif',
        fontSize: '18px',
        fontWeight: 'normal',
        fontStyle: 'italic',
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
        color: '#000',
    };

    const familyNameStyle = {
        fontStyle: 'italic',
        fontWeight: 'bold',
    };

    const teamContainerStyle = {
        paddingTop: '2px',
        paddingBottom: '8px',
    };

    const teamStyle = {
        display: 'inline-block',
        verticalAlign: 'top',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#1fdf6f',
        backgroundColor: '#282828',
        padding: '2px 4px',
    };

    const contactInfoStyle = {
        fontFamily: 'sans-serif',
        fontSize: '11px',
        color: '#282828',
    };

    const badgesContainerStyle = {
        paddingTop: '13px',
    };

    const pipe = () => (<img style={imgStyle} src={pipeUrl} width={10} height={10}/>);
    const longPipe = () => (<img style={imgStyle} src={longPipeUrl} width={45} height={82}/>);

    const createBadgesColumns = () => {
        let array = [];
        for (const badge of (badges ?? [])) {
            array.push(
                <td key={badge} valign={'middle'}>
                    <img
                        style={{
                            ...imgStyle,
                            marginLeft: '1px',
                            marginRight: '1px',
                        }}
                        src={getAwsBadgeUrl(badge)}
                        width={52}
                        height={60}
                    />
                </td>
            );
        }
        return array.length ? (
            <>
                { array }
                <td>
                    { longPipe() }
                </td>
            </>
        ) : (<></>);
    };

    function copySelectionToClipboard(element) {
        let range, selection;

        element.style.userSelect = 'all';
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);

        try {
            document.execCommand('copy');
            setInfoModal({
                // I18N
                message: 'Copiado para a área de transferência. Agora é só colar no seu cliente de email.',
                show: true,
            });
        } catch (e) {
            console.error('Fail to copy to clipboard', e);
            setInfoModal({
                // I18N
                message: 'Falha ao copiar para a área de transferência. Para mais detalhes, verifique o console do navegador.',
                show: true,
            });
        } finally {
            element.style.userSelect = '';
            selection.removeAllRanges();
        }
    }


    async function copyToClipboard() {
        if (name.trim() === '') {
            setInfoModal({
                // I18N
                message: 'Preencha o campo "Nome" para copiar a assinatura.',
                show: true,
            });
            return;
        } else if (familyName.trim() === '') {
            setInfoModal({
                // I18N
                message: 'Preencha o campo "Sobrenome" para copiar a assinatura.',
                show: true,
            });
            return;
        } else if (team.trim() === '') {
            setInfoModal({
                // I18N
                message: 'Preencha o campo "Time" para copiar a assinatura.',
                show: true,
            });
            return;
        }

        // noinspection JSUnresolvedReference
        if (embedImages) {
            // noinspection JSUnresolvedReference
            for (const img of signatureRef.current.querySelectorAll('img')) {
                if (img.src.startsWith('http')) {
                    // noinspection JSCheckFunctionSignatures
                    await fetch(img.src)
                        .then(response => response.blob())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            return new Promise((resolve, reject) => {
                                reader.onload = () => {
                                    const base64Image = reader.result;
                                    img.setAttribute('data-src', img.src);
                                    img.onload = () => {
                                        resolve();
                                    };
                                    img.onerror = reject;
                                    img.src = base64Image;
                                };
                            });
                        });
                }
            }
        } else {
            for (const img of signatureRef.current.querySelectorAll('img')) {
                const dataSrc = img.getAttribute('data-src');
                if (dataSrc) {
                    img.src = dataSrc;
                    img.removeAttribute('data-src');
                }
            }
        }
        copySelectionToClipboard(signatureRef.current);
    }

    return (
        <div className={'preview'}>
            <div>
                { /*I18N*/ }
                <label>{ 'Visualização' }</label>
                <div className={'signature-container'}>
                    <div ref={signatureRef}>
                        <div>&nbsp;</div>
                        <table {...tableAttributes}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table {...tableAttributes}>
                                            <tbody>
                                                <tr>
                                                    <td valign={'middle'}>
                                                        <img style={imgStyle} src={tripleSlashUrl} width={20} height={16}/>
                                                    </td>
                                                    <td valign={'top'}>
                                                        <div style={{
                                                            ...nameStyle,
                                                            marginLeft: '-2px',
                                                        }}>
                                                            { name ?? '' }
                                                            <span style={{ ...nameStyle, ...familyNameStyle }}>
                                                                { ` ${familyName ?? ''}` }
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign={'top'} style={teamContainerStyle}>
                                        <span style={teamStyle}>
                                            { getTeamEnumDescriptionBr(team) ?? '' }
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table {...tableAttributes}>
                                            <tbody>
                                                <tr>
                                                    <td valign={'middle'}>
                                                        <a
                                                            style={anchorStyle}
                                                            href={`tel:+55${unmaskPhone(mainPhone)}`}
                                                        >
                                                            <font style={{ ...anchorFontStyle, ...contactInfoStyle }}>
                                                                { `+55 ${mainPhone.replace(/\D/g, ' ')}` }
                                                            </font>
                                                        </a>
                                                    </td>
                                                    { phone && (
                                                        <>
                                                            <td valign={'middle'}>
                                                                { pipe() }
                                                            </td>
                                                            <td valign={'middle'}>
                                                                <a
                                                                    style={anchorStyle}
                                                                    href={`tel:+55${unmaskPhone(phone)}`}
                                                                >
                                                                    <font style={{ ...anchorFontStyle, ...contactInfoStyle }}>
                                                                        { `+55 ${phone.replace(/\D/g, ' ')}` }
                                                                    </font>
                                                                </a>
                                                            </td>
                                                        </>
                                                    ) }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table {...tableAttributes}>
                                            <tbody>
                                                <tr>
                                                    <td valign={'middle'}>
                                                        <a
                                                            style={anchorStyle}
                                                            href={'https://codebit.com.br'}
                                                            target={'_blank'} rel={'noreferrer'}
                                                        >
                                                            <font style={{ ...anchorFontStyle, ...contactInfoStyle }}>
                                                                { 'codebit.com.br' }
                                                            </font>
                                                        </a>
                                                    </td>
                                                    <td valign={'middle'}>
                                                        { pipe() }
                                                    </td>
                                                    <td valign={'middle'}>
                                                        <a
                                                            style={anchorStyle}
                                                            href={'https://www.instagram.com/codebitbr/'}
                                                            target={'_blank'} rel={'noreferrer'}
                                                        >
                                                            <font style={{ ...anchorFontStyle, ...contactInfoStyle }}>
                                                                { '@codebitbr' }
                                                            </font>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={badgesContainerStyle}>
                                        <table {...tableAttributes}>
                                            <tbody>
                                                <tr>
                                                    { !!(badges && badges.length) &&
                                                        ( createBadgesColumns() )
                                                    }
                                                    <td valign={'middle'}>
                                                        <img src={certifiedBCorporationUrl} width={42} height={64}/>
                                                    </td>
                                                    <td valign={'middle'}>
                                                        { longPipe() }
                                                    </td>
                                                    <td valign={'middle'}>
                                                        <img src={awsPartnerAdvanced} width={74} height={62}/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'controls'}>
                    { /*<FieldCheckbox*/ }
                    { /*    inputs={(() => {*/ }
                    { /*        let array = [];*/ }
                    { /*        array.push({*/ }
                    { /*            label: 'Incorporar imagens (necessário para alguns clientes de email, como o da Apple)',*/ }
                    { /*            checked: embedImages,*/ }
                    { /*            onChange: (e) => {*/ }
                    { /*                setEmbedImages(e.target.checked);*/ }
                    { /*            }*/ }
                    { /*        });*/ }
                    { /*        return array;*/ }
                    { /*    })()}*/ }
                    { /*/>*/ }

                    <button onClick={copyToClipboard}>
                        { /*I18N*/ }
                        { 'Copiar para área de transferência' }
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Preview;
import SignatureForm from './SignatureForm';
import { CodebitLogo, CodebitLogo2x } from '../components/images';
import './SignatureGenerator.scss';
import Preview from './Preview';
import { useState } from 'react';
import InfoModal from '../components/modal/InfoModal';

function SignatureGenerator() {
    const [ formData, setFormData ] = useState(null);
    const [ infoModal, setInfoModal ] = useState({
        message: '',
        show: false,
    });

    return (
        <>
            <div className={`signature-generator ${infoModal.show ? 'modal-background-blur' : ''}`}>
                <div className={'header'}>
                    <img src={CodebitLogo} srcSet={`${CodebitLogo} 1x, ${CodebitLogo2x} 2x`} alt={'Codebit'}/>
                    <h1>
                        { /*I18N*/ }
                        { 'Gerador de assinaturas de email' }
                    </h1>
                </div>
                <div className={'body'}>
                    <SignatureForm
                        onChange={(formData) => setFormData(formData)}
                        infoModal={setInfoModal}
                    />
                    <Preview
                        {...formData}
                        infoModal={setInfoModal}
                    />
                </div>
            </div>
            <InfoModal
                message={infoModal.message}
                show={infoModal.show}
                onClose={() => setInfoModal({ ...infoModal, show: false })}
            />
        </>
    );
}

export default SignatureGenerator;
import React from 'react';
import SignatureGenerator from './signature-generator/SignatureGenerator';
import './components/styles/App.scss';

function App() {
    return (
        <SignatureGenerator/>
    );
}

export default App;

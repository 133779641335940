// const PATH = '/assets/images/aws-badges/';
const PATH = 'assets/images/';

const AwsBadgesEnum = Object.freeze({
    ADVANCE_NETWORKING_SPECIALTY: 'aws-advanced-networking-specialty',
    CERTIFIED_CLOUD_PRACTITIONER: 'aws-certified-cloud-practitioner',
    DATA_ANALYTICS_SPECIALTY: 'aws-data-analytics-specialty',
    DATABASE_SPECIALTY: 'aws-database-specialty',
    DEVELOPER_ASSOCIATE: 'aws-developer-associate',
    DEVOPS_ENGINEER_PROFESSIONAL: 'aws-devops-engineer-professional',
    MACHINE_LEARNING_SPECIALTY: 'aws-machine-learning-specialty',
    SAP_ON_AWS_SPECIALTY: 'aws-sap-on-aws-specialty',
    SECURITY_SPECIALTY: 'aws-security-specialty',
    SOLUTIONS_ARCHITECT_ASSOCIATE: 'aws-solutions-architect-associate',
    SOLUTIONS_ARCHITECT_PROFESSIONAL: 'aws-solutions-architect-professional',
    SYSOPS_ADMINISTRATOR_ASSOCIATE: 'aws-sysops-administrator-associate',
});

function getAwsBadgeUrl(badge) {
    const urlBase = window.location.href;
    return urlBase + PATH + badge + `.png?${new Date().getDate()}`;
}

function getAwsBadgeEnumDescriptionBr(badge) {
    if (!badge) return null;
    switch (badge) {
    case AwsBadgesEnum.ADVANCE_NETWORKING_SPECIALTY:
        return 'Advance Networking Specialty';
    case AwsBadgesEnum.CERTIFIED_CLOUD_PRACTITIONER:
        return 'Certified Cloud Practitioner';
    case AwsBadgesEnum.DATA_ANALYTICS_SPECIALTY:
        return 'Data Analytics Specialty';
    case AwsBadgesEnum.DATABASE_SPECIALTY:
        return 'Database Specialty';
    case AwsBadgesEnum.DEVELOPER_ASSOCIATE:
        return 'Developer Associate';
    case AwsBadgesEnum.DEVOPS_ENGINEER_PROFESSIONAL:
        return 'DevOps Engineer Professional';
    case AwsBadgesEnum.MACHINE_LEARNING_SPECIALTY:
        return 'Machine Learning Specialty';
    case AwsBadgesEnum.SAP_ON_AWS_SPECIALTY:
        return 'SAP on AWS Specialty';
    case AwsBadgesEnum.SECURITY_SPECIALTY:
        return 'Security Specialty';
    case AwsBadgesEnum.SOLUTIONS_ARCHITECT_ASSOCIATE:
        return 'Solutions Architect Associate';
    case AwsBadgesEnum.SOLUTIONS_ARCHITECT_PROFESSIONAL:
        return 'Solutions Architect Professional';
    case AwsBadgesEnum.SYSOPS_ADMINISTRATOR_ASSOCIATE:
        return 'SysOps Administrator Associate';
    default:
        return null;
    }
}

export { AwsBadgesEnum, getAwsBadgeUrl, getAwsBadgeEnumDescriptionBr };
import React from 'react';
import MaskedInput from 'react-text-mask';
import './styles/Form.scss';

const brDefaultMask = [ '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/ ];
const brPhoneMask = [ '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];

export function PhoneInput(props) {
    props = { ...props };
    delete props.mask;
    delete props.placeholderChar;

    const value = (props.value ?? '').trimEnd();
    let mask = value.length <= brDefaultMask.length - 1 ? brDefaultMask : brPhoneMask;
    return <MaskedInput
        mask={mask}
        placeholderChar={' '}
        {...props}/>;
}

export function unmaskPhone(str) {
    if (!str) return null;
    const value = parseInt(str.replace(/\D/g, ''));
    return isNaN(value) ? null : value;
}
import { PhoneInput } from './PhoneInput';
import nextId from 'react-id-generator';
import './styles/Form.scss';

function FieldPhone(props) {
    const id = props.id ?? nextId('field-phone');
    const idError = `${id}-error`;
    const { label, validationError, required } = props;

    props = { ...props };

    delete props.required;
    delete props.validationError;
    delete props.label;

    return (
        <div className={`field-group ${validationError ? 'error' : ''}`}>
            <label className={'field-label'} htmlFor={id}>
                { label }
            </label>
            <PhoneInput
                id={id}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                {...props}
            />
            <div id={idError} className={'validation-error'}>
                { validationError }
            </div>
        </div>
    );
}

export default FieldPhone;
import nextId from 'react-id-generator';
import './styles/Form.scss';

function FieldSelect(props) {
    const id = props.id ?? nextId('field-select');
    const idError = `${id}-error`;
    const { options, label, validationError, required, placeholder, fieldGroup } = props;

    props = { ...props };

    delete props.placeholder;
    delete props.required;
    delete props.validationError;
    delete props.label;
    delete props.options;
    delete props.fieldGroup;

    function createPlaceholderOption() {
        if (placeholder) {
            return (
                <option key={'-1'} value={'-1'} disabled>
                    { placeholder }
                </option>
            );
        } else {
            return (<></>);
        }
    }

    function createOptions() {
        let array = [];
        for (const option of options) {
            array.push(
                <option key={option.key} value={option.key}>
                    { option.value }
                </option>
            );
        }
        return array;
    }

    return (
        <div className={`${(fieldGroup ?? true ? 'field-group' : '')} ${validationError ? 'error' : ''}`}>
            {
                label
                    ?
                    <label className={'field-label'} htmlFor={id}>
                        { label }
                    </label>
                    :
                    <></>
            }
            <select
                id={id}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                {...props}
            >
                { createPlaceholderOption() }
                { createOptions() }
            </select>
            <div id={idError} className={'validation-error'}>
                { validationError }
            </div>
        </div>
    );
}

export default FieldSelect;
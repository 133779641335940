import nextId from 'react-id-generator';
import './styles/Form.scss';

function FieldText(props) {
    const id = props.id ?? nextId('field-text');
    const idError = `${id}-error`;
    const { label, validationError, required } = props;

    props = { ...props };

    delete props.required;
    // noinspection JSUnresolvedReference
    delete props.validationError;
    delete props.label;

    return (
        <div className={`field-group ${validationError ? 'error' : ''}`}>
            {
                label
                    ?
                    <label className={'field-label'} htmlFor={id}>
                        { label }
                    </label>
                    :
                    <></>
            }
            <input
                id={id}
                type={'text'}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                {...props}
            />

            <div id={idError} className={'validation-error'}>
                { validationError }
            </div>
        </div>
    );
}

export default FieldText;
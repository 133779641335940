const TeamsEnum = Object.freeze({
    MARKETING: 'marketing',
    DEVELOPMENT: 'development',
    COMERCIAL: 'comercial',
    MANAGEMENT: 'management',
    CLOUDOPS: 'cloudops',
    FINANCE: 'finance',
    QA: 'qa',
});

// I18N
function getTeamEnumDescriptionBr(team) {
    if (!team) return null;
    switch (team) {
    case TeamsEnum.MARKETING: return 'Marketing';
    case TeamsEnum.DEVELOPMENT: return 'Desenvolvimento';
    case TeamsEnum.COMERCIAL: return 'Comercial';
    case TeamsEnum.MANAGEMENT: return 'Gestão';
    case TeamsEnum.CLOUDOPS: return 'CloudOps';
    case TeamsEnum.FINANCE: return 'Financeiro';
    case TeamsEnum.QA: return 'Testes e Qualidade';
    default: return null;
    }
}

export {
    TeamsEnum, getTeamEnumDescriptionBr
};
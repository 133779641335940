import '../styles/Modal.scss';

function LoadingModal(props) {
    return (
        <div className={`modal info-modal ${props.show ? 'show' : ''}`} style={{ zIndex: props.zIndex ?? 10010 }}>
            <div className={'backdrop'}/>
            <div className={'container'}>
                <div className={'message'}>
                    { props.message }
                </div>
                <div className={'form-controls'}>
                    <button
                        onClick={props.onClose}
                    >
                        { /*I18N*/ }
                        { 'Ok' }
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoadingModal;
import nextId from 'react-id-generator';
import './styles/Form.scss';

function FieldCheckbox(props) {
    const id = props.id ?? nextId('field-checkbox');
    const idError = `${id}-error`;
    const { inputs, label, validationError, className } = props;

    props = { ...props };

    delete props.inputs;
    delete props.validationError;
    delete props.label;

    function createInputs() {
        let array = [];
        for (let input of inputs) {
            const { label } = input;
            input = { ...input };
            delete input.label;
            const type = input.type === 'radio' ? input.type : 'checkbox';
            array.push(
                <label key={nextId(`$input-${type}`)}>
                    <input
                        type={type}
                        aria-invalid={Boolean(validationError)}
                        aria-errormessage={idError}
                        name={id}
                        {...input}
                    />
                    { label }
                </label>
            );
        }
        return array;
    }

    return (
        <div className={`field-group ${validationError ? 'error' : ''} ${className ?? ''}`}>
            {
                label ?
                    <label className={'field-label'} htmlFor={id}>
                        { label }
                    </label>
                    : <></>
            }
            <div className={'field-inputs'}>
                { createInputs() }
            </div>
            <div id={idError} className={'validation-error'}>
                { validationError }
            </div>
        </div>
    );
}

export default FieldCheckbox;